import type { GetProjectDataResponse, SearchRequest } from "@/types";
import type { AuthUserResponse } from "@/types/api";
import { axios, useCache } from "../axios";
import { SortOrder } from "@/constants";
import { useUserStore } from "@/stores/user";
import type { CacheRequestConfig } from "axios-cache-interceptor";
import type {
  GetAdminProjectsViaSearchResponse,
  PutAdminProjectRequest,
  PutAdminProjectResponse
} from "@/types/AdminProject";

export const getProjectData = (
  projectId: Guid,
  config: CacheRequestConfig<GetProjectDataResponse> = {}
) => {
  return axios
    .get<GetProjectDataResponse>("api/data/internal/Project/projectData", {
      params: { projectId },
      cache: useCache({
        ttl: 15 * 60 * 1000,
        update: {
          // Someone else might have changed some data for this building
          // so we need to update the building in the userStore and user cache
          // when we retrieve the projectData
          user: (userCacheResponse, projectDataResponse) => {
            if (userCacheResponse.state !== "cached") return "ignore";

            const userCache = userCacheResponse.data.data as AuthUserResponse;
            const buildings = userCache.data.buildingAccessInformation;

            const buildingId = projectDataResponse.data.data.buildings[0].id;

            const index = buildings.findIndex(
              ({ buildingInformation }) => buildingInformation.id === buildingId
            );

            if (index < 0) {
              return "delete";
            }

            // Update cache
            buildings[index].buildingInformation = projectDataResponse.data.data.buildings[0];

            // Update user store
            const user = useUserStore();
            user.updateBuilding(buildingId, buildings[index].buildingInformation);

            return userCacheResponse;
          }
        }
      }),
      ...config
    })
    .then(response => response.data);
};

export const searchProjects = async (request: Partial<SearchRequest>) => {
  return axios
    .post<GetAdminProjectsViaSearchResponse, Partial<SearchRequest>>(
      "api/administration/Project/search",
      {
        pageNumber: request.pageNumber || 1,
        pageSize: request.pageSize || 100,
        criteria: request.criteria || [],
        field: request.field || "name",
        order: request.order || SortOrder.Ascending
      }
    )
    .then(response => response.data);
};

export const updateProject = (projectId: Guid, name: string, referenceId: string) => {
  return axios
    .put<
      PutAdminProjectResponse,
      PutAdminProjectRequest
    >("api/administration/Project", { id: projectId, name, referenceId })
    .then(response => response.data);
};
